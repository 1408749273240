<template>
    <v-container id="about">
        <v-row>
            <v-col cols="12">
                <p>
                    HOLA PUEDES ENVIARNOS TUS COMENTARIOS, DUDAS, O ACLARACIONES EN: 
                    <a href="mailto: contacto@rawk.mx">CONTACTO@RAWK.MX </a> 
                </p>
                <p>
                    SIGUENOS EN NUESTRAS REDES 
                    <a  href="https://www.facebook.com/Rawk-111546357699179" target="_blank">
                        FACEBOOK
                    </a>
                    -
                    <a  href="https://www.instagram.com/rawk.mx/" target="_blank">
                        INSTAGRAM
                    </a>
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    
}
</script>

